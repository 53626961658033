import { modelAtom } from "@/financeModels/owasco/model";
import { Button, Group } from "@mantine/core";
import { useHash } from "@mantine/hooks";
import { useAtom } from "jotai";

export const DownloadUploadButtons = () => {
  const [hash] = useHash();
  const [model, setModel] = useAtom(modelAtom);

  const download = () => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(
      new Blob([JSON.stringify(model)], { type: "application/json" }),
    );
    a.download = `${new Date()
      .toISOString()
      .replace(/:/g, "-")
      .replace(/\..+/, "")}.json`;
    a.click();
  };

  const upload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/json";
    input.onchange = async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (!file) return;

      try {
        const text = await file.text();
        const obj = JSON.parse(text);
        console.log({ obj, model });
        setModel(obj);
        alert("Model uploaded");
      } catch (e) {
        alert("Failed to parse JSON");
      }
    };
    input.click();
  };

  if (!["#download", "#upload"].includes(hash)) return null;

  return (
    <Group justify="center" pr={"xs"}>
      <Button flex={1} onClick={() => download()}>
        Download Model
      </Button>
      <Button flex={1} onClick={() => upload()}>
        Upload Model
      </Button>
    </Group>
  );
};
