import { Model } from "@/resources/models";
import { formatDollar } from "@/utils/format";
import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { Timestamp } from "firebase/firestore";
import parseMoney from "parse-money";
import { ActionRenderer } from "./renderers/ActionRenderer";
import { DealRenderer } from "./renderers/DealRenderer";
import { ModelOwnerRenderer } from "./renderers/ModelOwnerRenderer";
import { NamerRenderer } from "./renderers/NameRenderer";

export const defautColDefs: ColDef = {
  editable: false,
  resizable: false,
  flex: 1,
  sortingOrder: ["asc", "desc"],
  suppressHeaderFilterButton: true,
  suppressMovable: true,
  minWidth: 118,
} as const;

const defaultDateField: Partial<ColDef> = {
  headerName: "Date",
  cellDataType: "date",
  valueSetter: (params) => {
    params.data.createdAt = Timestamp.fromDate(new Date(params.newValue));
    return true;
  },
  valueGetter: (params) => params?.data?.createdAt?.toDate(),
  //editable: false,
  minWidth: 118,
  maxWidth: 118,
  //sort: "desc",
  //sortIndex: 1,
  //cellStyle: { textAlign: "center" },
} as const;

const dollarOptions: Partial<ColDef> = {
  // parse value to float, fix columns and decimals
  valueParser: (params) => parseMoney(params.newValue.toString())?.amount || 0,
  valueFormatter: (params: ValueFormatterParams) =>
    params.value > 0 ? formatDollar(params.value).replace(".00", "") : "",
} as const;

export const colDefs: ColDef[] = [
  {
    field: "name",
    minWidth: 255,
    cellRenderer: NamerRenderer,
  },
  {
    field: "deal",
    minWidth: 255,
    cellRenderer: DealRenderer,
    sortable: false,
  },
  {
    field: "excerpt.targetPurchasePrice",
    headerName: "Purchase Price",
    ...dollarOptions,
  },
  {
    field: "isShared",
    type: "boolean",
    valueGetter: (params) => {
      return params.data.allowedEmails?.length > 0;
    },
    filter: true,
    suppressHeaderFilterButton: true,
    suppressHeaderMenuButton: true,
    hide: true,
  },
  {
    field: "excerpt.cashFlow",
    headerName: "Cash Flow",
    ...dollarOptions,
  },
  {
    field: "purchaseMultiple",
    headerName: "Purchase Multiple",
    editable: false,
    valueGetter: (params) => {
      const model = params.data as Model;
      if (!model?.excerpt?.cashFlow) return null;
      if (!model?.excerpt?.targetPurchasePrice) return null;

      return model?.excerpt?.targetPurchasePrice / model?.excerpt?.cashFlow;
    },
    valueFormatter: (params) =>
      params.value ? params.value.toFixed(2) + "x" : "",

    headerTooltip: "Auto-calculated using Cash Flow (SDE) and Purchase Price",
  },
  {
    field: "owner",
    cellRenderer: ModelOwnerRenderer,
    minWidth: 120,
    sortable: false,
  },
  {
    ...defaultDateField,
    field: "updatedAt",
    headerName: "Updated",
    sort: "desc",
    // use createdAt as a fallback if updatedAt is null
    valueGetter: (params) =>
      params?.data?.updatedAt?.toDate() || params?.data?.createdAt?.toDate(),
  },
  { ...defaultDateField, field: "createdAt", headerName: "Created" },
  {
    field: "actions",
    editable: false,
    sortable: false,
    cellRenderer: ActionRenderer,
    minWidth: 120,
  },
] as const;
