import { EntrySidebarActions } from "@/components/EntrySidebarActions/EntrySidebarActions";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { TagInputEditor } from "@/components/TagInput/TagInputEditor";
import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import { Route } from "@/paths";
import {
  fixLegacyCategoryIdValue,
  useDealDirectoryCategoryTags,
} from "@/resources/dealDirectories";
import { useDealEntry } from "@/resources/deals";
import { Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { ModelsList } from "./ModelsList";

export const Sidebar = () => {
  const entry = useDealEntry();
  const { inactiveElementProps } = useIsPlanInactive();

  return (
    <LayoutWorkstation.Sidebar size={"sm"}>
      <LayoutWorkstation.Header>
        <LayoutWorkstation.Header.Breadcrumbs>
          <Text
            component={Link}
            fw={600}
            to={Route.DealTracker}
            lh={1}
            c="blue"
          >
            Deal Tracker
          </Text>
        </LayoutWorkstation.Header.Breadcrumbs>
      </LayoutWorkstation.Header>
      <Stack flex={1} p="xs" justify="space-between" {...inactiveElementProps}>
        <Stack gap={"xs"}>
          <EditTags />
          <ModelsList />
        </Stack>
        <EntrySidebarActions
          entry={entry}
          confirmationPath={Route.DealTracker}
        />
      </Stack>
    </LayoutWorkstation.Sidebar>
  );
};

const EditTags = () => {
  const [tagOptions] = useDealDirectoryCategoryTags();
  const entry = useDealEntry();
  const [item] = entry.useItem();

  return (
    <TagInputEditor
      label="Status"
      callToAction="Change status..."
      allowMultiple={false}
      value={fixLegacyCategoryIdValue(item?.categoryId || [])}
      onValueChange={(value) => {
        const newVal = fixLegacyCategoryIdValue(value);

        entry.update({
          categoryId: newVal || null,
        });
      }}
      tagOptions={tagOptions || []}
      size="md"
      styles={
        {
          label: { fontSize: 14, fontWeight: 600 },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
      }
    />
  );
};
