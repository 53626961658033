import { ActionButtonGroup } from "@/components/AppGrid/ActionButtons/ActionButtonGroup";
import { EditButton } from "@/components/AppGrid/ActionButtons/EditButton";
import { Icons } from "@/components/Icons/Icons";
import { openShareModal } from "@/components/PageModel/Main/SharingButton";
import { useRemoveDealModel } from "@/hooks/useDeal";
import { useModelDeal } from "@/hooks/useModelDeal";
import { useRemoveModel } from "@/hooks/useModels";
import { Route } from "@/paths";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { ActionIcon, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { generatePath } from "react-router-dom";

export const ActionRenderer = (props: CustomCellRendererProps) => {
  const [deal] = useModelDeal(props.data.id);
  const [removeModelWithDeal] = useRemoveDealModel();
  const [removeModel] = useRemoveModel();

  const remove = async () => {
    if (!deal) {
      return await removeModel(props.data.id);
    }

    await removeModelWithDeal(deal.id, props.data.id);
  };

  return (
    <ActionButtonGroup>
      <ActionIcon variant={"light"} onClick={() => openShareModal(props.data)}>
        <Icons.Share />
      </ActionIcon>

      <EditButton
        path={generatePath(Route.Model, { modelId: props.data.id })}
      />

      <ActionIcon
        variant={"light"}
        onClick={() => openDeleteModal(props.data.name, remove)}
      >
        <Icons.Delete />
      </ActionIcon>
    </ActionButtonGroup>
  );
};

const openDeleteModal = async (name: string, removeFn: () => void) => {
  await modals.openConfirmModal({
    title: "Delete this model?",

    children: (
      <Text size="md">
        Are you sure you want to delete the '{name || "Untitled"}' model?
      </Text>
    ),
    labels: {
      confirm: `Delete`,
      cancel: "Cancel",
    },
    confirmProps: { color: "red" },
    onConfirm: async () => {
      removeFn();
    },
  });
};
