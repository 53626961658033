import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { useContactEntry } from "@/resources/contacts";
import { Group } from "@mantine/core";

export const Header = () => {
  const entry = useContactEntry();
  const [contact] = entry.useItem();

  return (
    <LayoutWorkstation.Header>
      <Group px={"xs"} h={"100%"} w={"100%"} align="center" gap={6}>
        <LayoutWorkstation.Header.BreadcrumbTitle>
          Contact
        </LayoutWorkstation.Header.BreadcrumbTitle>
        <LayoutWorkstation.Header.BreadcrumbSeperator />
        <LayoutWorkstation.Header.BreadcrumbTitle>
          {contact?.name || "(Untitled Contact)"}
        </LayoutWorkstation.Header.BreadcrumbTitle>
      </Group>
    </LayoutWorkstation.Header>
  );
};
