import {
  useContactDirectoryEntry,
  useContactDirectoryJobTitleTags,
} from "@/resources/contactDirectories";
import { Contact } from "@/resources/contacts";
import {
  Button,
  Group,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { BiCog } from "react-icons/bi";
import { TagInputEditor } from "../TagInput/TagInputEditor";
import { TagPopoverEditor } from "../TagPopoverEditor/TagPopoverEditor";

export const EditContactForm = ({
  initialValues,
  onSubmit,
  callToAction = "Add Contact",
  onValuesChange,
}: {
  initialValues?: Partial<Contact>;
  onSubmit?: (values: Partial<Contact>) => void;
  callToAction?: string;
  onValuesChange?: (values: Partial<Contact>) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [tagOptions] = useContactDirectoryJobTitleTags();
  const contactsDirectoryEntry = useContactDirectoryEntry();

  const form = useForm({
    mode: "uncontrolled",

    initialValues: {
      email: "",
      phone: "",
      location: "",
      website: "",
      ...initialValues,
    } satisfies Partial<Contact>,
    onValuesChange: (values) => {
      onValuesChange?.(values);
    },
  });

  const handleSubmit = async (values: Partial<Contact>) => {
    if (!onSubmit) return;
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap={"xs"} mb={"lg"}>
        <SimpleGrid cols={2}>
          <Stack gap={"xs"}>
            <TextInput
              label="Name"
              data-autofocus
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
            <TextInput
              label="Email"
              data-autofocus
              key={form.key("email")}
              {...form.getInputProps("email")}
            />
            <TextInput
              label="Phone"
              key={form.key("phone")}
              {...form.getInputProps("phone")}
            />
            <TextInput
              label="Website"
              key={form.key("website")}
              {...form.getInputProps("website")}
            />{" "}
            <TextInput
              label="Remarks"
              key={form.key("remarks")}
              {...form.getInputProps("remarks")}
            />
          </Stack>
          <Stack gap={"xs"}>
            <TextInput
              label="Location"
              key={form.key("location")}
              {...form.getInputProps("location")}
            />
            <TextInput
              label="Firm / Organization"
              data-autofocus
              key={form.key("firm")}
              {...form.getInputProps("firm")}
            />
            <TagInputEditor
              label={
                <Group justify="space-between" w={200} h={12}>
                  <Text fw={500} fz={14} lh={1}>
                    Roles
                  </Text>
                  <TagPopoverEditor
                    entry={contactsDirectoryEntry}
                    resourceProp={"jobTitleTags"}
                  >
                    <BiCog />
                  </TagPopoverEditor>
                </Group>
              }
              value={form.getValues().jobTitleIds || []}
              onValueChange={(value) => {
                form.setFieldValue("jobTitleIds", value);
              }}
              callToAction="Add Role..."
              tagOptions={tagOptions || []}
            />
          </Stack>
        </SimpleGrid>

        {onSubmit && (
          <>
            <Button type="submit" fullWidth loading={loading} mt="md">
              {callToAction}
            </Button>
            <Text ta={"center"} size={"xs"} c={"dimmed"} px={"xl"}>
              Everywhere this contact appears will be updated.
            </Text>
          </>
        )}
      </Stack>
    </form>
  );
};
