import { Route } from "@/paths";
import { ZIndexOrder } from "@/theme";
import { Box, Group, Stack, Text } from "@mantine/core";
import { CgArrowRight } from "react-icons/cg";
import { RiErrorWarningFill } from "react-icons/ri";
import { Link } from "react-router-dom";

export const NotAvailableOnMobile = () => {
  return (
    <Box
      pos="fixed"
      left={0}
      top={0}
      bg="gray.3"
      w="100dvw"
      h="100dvh"
      style={{ zIndex: ZIndexOrder.NotAvailableOnMobileWarning }}
      hiddenFrom="xs"
      p={"xl"}
      ta={"center"}
    >
      <Stack align="center" pt={"10vh"} gap={20}>
        <Text fw={"bold"}>
          The ETA IQ was designed for
          <br /> desktop and tablets.
        </Text>
        <Group align="center">
          <RiErrorWarningFill size={200} />
        </Group>
        <Text>Please use a larger device to access the app.</Text>
        <Text size="sm" c="gray" component={Link} to={Route.Profile}>
          Go to your user profile <CgArrowRight />
        </Text>
      </Stack>
    </Box>
  );
};
