import { Route } from "@/paths";
import { BoxComponentProps, Group, Text } from "@mantine/core";
import { ReactNode } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link, To } from "react-router-dom";
import { Logo } from "../Logo/Logo";

export const defaultHeaderHeight = 42;

export const Header = ({
  children,
  headerHeight = defaultHeaderHeight,
  ...props
}: {
  children: ReactNode;
  headerHeight?: number;
} & BoxComponentProps) => {
  return (
    <Group
      mih={headerHeight}
      h={headerHeight}
      align="center"
      style={{
        borderBottom: "1px solid #ddd",
        overflow: "hidden",
      }}
      bg="gray.1"
      justify="space-between"
      gap={"xs"}
      {...props}
    >
      {children}
    </Group>
  );
};

Header.Breadcrumbs = ({ children }: { children: ReactNode }) => {
  return (
    <Group px={"xs"} h={"100%"} w={"100%"} align="center" gap={6}>
      <Logo w={56} variant="dark" to={Route.DealTracker} />
      <Header.BreadcrumbSeperator />
      {children}
    </Group>
  );
};

Header.BreadcrumbTitle = ({
  children,
  to,
}: {
  children: ReactNode;
  to?: To;
}) => {
  return (
    <Text
      lh={1}
      fw={600}
      fz={"md"}
      {...(to ? { component: Link, to } : { to: "" })}
    >
      {children}
    </Text>
  );
};

Header.BreadcrumbSeperator = () => {
  return <BiChevronRight />;
};
