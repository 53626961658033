import { useCreateDealModel } from "@/hooks/useDeal";
import { Route } from "@/paths";
import dealsResource, { Deal } from "@/resources/deals";
import {
  Button,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { limit, orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import { BiSolidAddToQueue } from "react-icons/bi";
import { RiFileAddFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { stringSimilarity } from "string-similarity-js";

export const CreateModelButton = ({ disabled }: { disabled?: boolean }) => {
  const navigate = useNavigate();

  const openAddModelModal = () => {
    modals.open({
      title: "Create New Model",
      children: <AddModelModal navigate={navigate} />,
    });
  };

  return (
    <Button
      w={180}
      leftSection={<BiSolidAddToQueue size={20} />}
      onClick={openAddModelModal}
      disabled={disabled}
    >
      Create Model
    </Button>
  );
};

const AddModelModal = ({
  navigate,
}: {
  navigate: ReturnType<typeof useNavigate>;
}) => {
  const [value, setValue] = useState("Add To New Deal");

  return (
    <Stack>
      <SegmentedControl
        color="blue"
        value={value}
        onChange={setValue}
        data={["Add To New Deal", "Add To Existing Deal"]}
        styles={{
          control: { borderRadius: 0, width: 160 },
        }}
      />
      {value === "Add To New Deal" ? (
        <AddToNewDeal navigate={navigate} />
      ) : (
        <AddExistingContact navigate={navigate} />
      )}
    </Stack>
  );
};

const AddToNewDeal = ({
  navigate,
}: {
  navigate: ReturnType<typeof useNavigate>;
}) => {
  const [createModel] = useCreateDealModel();
  const [loading, setLoading] = useState(false);
  const [dealName, setDealName] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const deal = await dealsResource.create({
      companyName: dealName,
    });
    const model = await createModel(deal.id);
    navigate(Route.Model.replace(":modelId", model!.id));
    setLoading(false);
    modals.closeAll();
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={"xs"}>
        <Text c="dimmed" size="sm" ta={"center"}>
          Create a model and add it to a new deal in the deal tracker.
        </Text>

        <TextInput
          label="Deal Name"
          value={dealName}
          onChange={(e) => setDealName(e.currentTarget.value)}
        />
        <Button type="submit" loading={loading} mt={"xs"}>
          Create New Model
        </Button>
      </Stack>
    </form>
  );
};

const AddExistingContact = ({
  navigate,
}: {
  navigate: ReturnType<typeof useNavigate>;
}) => {
  const [createModel] = useCreateDealModel();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<null | string>(null);
  const [allDeals, setAllDeals] = useState<Deal[] | null>();

  useEffect(() => {
    const fetchDeals = async () => {
      const deals =
        (await dealsResource.getItems({
          constraints: [orderBy("createdAt", "desc"), limit(3)],
        })) || [];
      setAllDeals(deals);
    };
    fetchDeals();
  }, []);

  useEffect(() => {
    if (loading) return;
    if (search.length < 3) return;
    setLoading(true);

    const fetchDeals = async () => {
      const deals = (await dealsResource.getItems()) || [];
      setAllDeals(deals);
    };
    fetchDeals();
  }, [search]);

  // filter and search deals name and email, firm with stringSimilarity > 0.5
  // limit to 10 deals

  const filteredDeals = allDeals
    ?.filter((contact) => {
      if (
        stringSimilarity(search, contact?.companyName) > 0.22 ||
        stringSimilarity(search, contact?.remarks) > 0.22
      )
        return true;

      if (search.length < 3) return false;

      return (
        contact?.companyName?.toLowerCase().includes(search.toLowerCase()) ||
        contact?.remarks?.toLowerCase().includes(search.toLowerCase())
      );
    })
    .slice(0, 10)
    .sort((a, b) => {
      return (
        stringSimilarity(search, a.companyName) -
        stringSimilarity(search, b.companyName)
      );
    });

  const onSelect = async (deal: Deal) => {
    setSaving(deal.id);
    const model = await createModel(deal.id);
    navigate(Route.Model.replace(":modelId", model!.id));
    modals.closeAll();
  };

  return (
    <Stack gap={"xs"} align="center">
      <Text c="dimmed" size="sm" ta={"center"}>
        Choose an existing deal and create a new model for it.
      </Text>
      <TextInput
        label="Search Deals"
        value={search}
        onChange={(e) => {
          setSearch(e.currentTarget.value);
        }}
        w={"100%"}
      />

      {filteredDeals?.map((deal) => (
        <Button
          key={deal.id}
          onClick={() => onSelect(deal)}
          w={"100%"}
          variant="outline"
          leftSection={<RiFileAddFill size={20} />}
          justify="flex-start"
          loading={saving === deal.id}
        >
          {deal.companyName || "(Untitle Deal)"}
        </Button>
      ))}
    </Stack>
  );
};
