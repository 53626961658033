import { NotesWriter } from "@/components/NotesWriter/NotesWriter";
import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import { Deal, useDealEntry } from "@/resources/deals";
import { Flex, Group, ScrollArea, Stack } from "@mantine/core";
import { Contacts } from "./Contacts";
import { EditDealForm } from "./EditDealForm";

export const Main = () => {
  const entry = useDealEntry();
  const { inactiveElementProps } = useIsPlanInactive();

  return (
    <Flex w={"100%"} {...inactiveElementProps}>
      <ScrollArea h={"calc(100dvh - 43px)"} w={{ base: "100%", lg: "64%" }}>
        <Stack p="sm">
          <EditDeal />
          <Contacts />
        </Stack>
      </ScrollArea>
      <Group flex={1} bg="white" align="top" miw={300}>
        <NotesWriter entry={entry} />
      </Group>
    </Flex>
  );
};

const EditDeal = () => {
  const entry = useDealEntry();
  const [deal] = entry.useItemOnce();

  const onValuesChange = (values: Partial<Deal>) => {
    entry.update({
      companyName: values.companyName,
      location: values.location,
      industry: values.industry,
      listingLink: values.listingLink,
      brokerage: values.brokerage,
      broker: values.broker,
      purchasePrice: values.purchasePrice || null,
      revenue: values.revenue || null,
      cashFlow: values.cashFlow || null,
      establishedAt: values.establishedAt,
    });
  };

  if (!deal) return null;

  return (
    <Stack>
      <EditDealForm initialValues={deal} onValuesChange={onValuesChange} />
    </Stack>
  );
};
