import { getShortId } from "@/utils/id";
import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { atomFamily } from "jotai/utils";
import { modelAtom } from "./model";
import { Epoch } from "./types";

export const epochsFocusAtom = focusAtom(modelAtom, (optic) =>
  optic.prop("epochs"),
);

const epochsAtomCache = atomFamily((epochId: string) =>
  focusAtom(epochsFocusAtom, (optic) => optic.prop(epochId)),
);

export const orderedEpochsMolecule = atom((get) => {
  const epochsMap = get(epochsFocusAtom);
  // sort by year, then by updatedAt
  return Object.values(epochsMap)
    .sort((a, b) => {
      if (a.year === b.year) {
        return (
          (a?.updatedAt || convertStringToNumberHash(a.id)) -
          (b?.updatedAt || convertStringToNumberHash(b.id))
        );
      }
      return a.year - b.year;
    })
    .map((epoch) => epochsAtomCache(epoch.id));
});

export const createEpochAtom = atom(
  null,
  (get, set, epoch?: Omit<Epoch, "id">) => {
    set(modelAtom, (prev) => {
      const epochId = getShortId();
      const epochAtoms = get(orderedEpochsMolecule);

      const year =
        epochAtoms.length > 0
          ? get(epochAtoms[epochAtoms.length - 1]).year + 1
          : new Date().getFullYear();

      const newEpoch: Epoch = {
        id: epochId,
        year,
        type: "full",
        createdAt: Date.now(),
        updatedAt: Date.now(),
        ...epoch,
      };
      const newEpochs = {
        ...prev.epochs,
        [epochId]: newEpoch,
      };
      return { ...prev, epochs: newEpochs };
    });
  },
);

export const deleteEpochAtom = atom(null, (_get, set, epochId: string) => {
  set(modelAtom, (prev) => {
    const newEpochs = { ...prev.epochs };
    delete newEpochs[epochId];
    return { ...prev, epochs: newEpochs };
  });
});

const convertStringToNumberHash = (str: string) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};
