import { EntrySidebarActions } from "@/components/EntrySidebarActions/EntrySidebarActions";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { useIsPlanInactive } from "@/hooks/useIsPlanInactive";
import { Route } from "@/paths";
import { useContactEntry } from "@/resources/contacts";
import { Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { DealList } from "./DealList";

export const Sidebar = () => {
  const entry = useContactEntry();
  const { inactiveElementProps } = useIsPlanInactive();
  return (
    <LayoutWorkstation.Sidebar size={"sm"}>
      <LayoutWorkstation.Header>
        <LayoutWorkstation.Header.Breadcrumbs>
          <Text
            component={Link}
            mt={".13em"}
            fw={600}
            to={Route.Contacts}
            c="blue"
          >
            Contacts
          </Text>
          {/* <LayoutWorkstation.Header.BreadcrumbSeperator />
          <LayoutWorkstation.Header.BreadcrumbTitle>
            Contact
          </LayoutWorkstation.Header.BreadcrumbTitle> */}
        </LayoutWorkstation.Header.Breadcrumbs>
      </LayoutWorkstation.Header>
      <Stack flex={1} p="xs" justify="space-between" {...inactiveElementProps}>
        <Stack gap={"xs"}>
          <DealList />
        </Stack>
        <EntrySidebarActions entry={entry} confirmationPath={Route.Contacts} />
      </Stack>
    </LayoutWorkstation.Sidebar>
  );
};
