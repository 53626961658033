import { TagRenderer } from "@/components/AppGrid/customCells/TagInput";
import { Route } from "@/paths";
import { getContactDirectoryEntry } from "@/resources/contactDirectories";
import { Contact } from "@/resources/contacts";
import { ColDef } from "@ag-grid-community/core";
import { Timestamp } from "firebase/firestore";
import { generatePath } from "react-router-dom";
import { LinkRenderer } from "../AppGrid/customCells/LinkRenderer";
import { NameRenderer } from "../AppGrid/customCells/NameRenderer";
import { ActionRenderer } from "./Main/ActionRenderer";
import { JobTitleTagEditor } from "./Main/JobTitleTagEditor";

export const defaultColDef: ColDef = {
  editable: true,
  suppressHeaderFilterButton: true,
  sortingOrder: ["asc", "desc"],
} as const;

export const colDefs: (ColDef & { field: keyof Contact })[] = [
  {
    field: "name",
    minWidth: 255,
    cellRenderer: NameRenderer,
    cellRendererParams: {
      resourceName: "contact",
      generatePath: (id: string) =>
        generatePath(Route.Contact, {
          contactId: id,
        }),
    },
  },
  {
    field: "createdAt",
    editable: false,
    headerName: "Created",
    cellDataType: "date",
    valueSetter: (params) => {
      params.data.createdAt = Timestamp.fromDate(new Date(params.newValue));
      return true;
    },
    valueGetter: (params) => params?.data?.createdAt?.toDate(),
    minWidth: 118,
    maxWidth: 118,
  },
  { field: "email" },
  {
    field: "phone",
  },
  { field: "firm", headerName: "Firm / Organization" },
  {
    headerName: "Role",
    field: "jobTitleIds",
    filter: true,

    cellRenderer: TagRenderer,
    cellEditor: JobTitleTagEditor,
    cellDataType: "object",
    cellEditorPopup: true,
    cellRendererParams: {
      getEntry: getContactDirectoryEntry,
      resourceProp: "jobTitleTags",
    },
  },
  {
    field: "website",
    cellRenderer: LinkRenderer,
  },
  { field: "location" },
  { field: "remarks" },
  {
    field: "updatedAt",
    headerName: "Last Updated",
    sort: "desc",
    cellDataType: "date",
    valueSetter: (params) => {
      params.data.updatedAt = Timestamp.fromDate(new Date(params.newValue));
      return true;
    },
    valueGetter: (params) => params?.data?.updatedAt?.toDate(),
    //editable: false,
    minWidth: 118,
    maxWidth: 118,
    editable: false,
  },
  {
    field: "actions" as unknown as keyof Contact,
    editable: false,
    filter: false,
    sortable: false,
    cellRenderer: ActionRenderer,
  },
] as const;
