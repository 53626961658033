import { ResourceEntry } from "@/resources/_resource";
import { Tag } from "@/resources/tags";
import { ActionIcon, Popover } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { EditTagList } from "../AppGrid/AppTagFilter/EditTagList";

export const TagPopoverEditor = ({
  entry,
  resourceProp,
  label,
  children,
}: {
  entry: ResourceEntry;
  resourceProp: string;
  label?: string;
  children?: React.ReactNode;
}) => {
  const [item] = entry.useItem();
  const resourceTags = item?.[
    resourceProp as unknown as keyof typeof item
  ] as unknown as Tag[];
  const editTagsPopoverDisclosure = useDisclosure(false);

  if (!item) return null;

  return (
    <Popover
      width={240}
      position="bottom-end"
      withArrow
      shadow="md"
      opened={editTagsPopoverDisclosure[0]}
      onChange={() => editTagsPopoverDisclosure[1].toggle()}
    >
      <Popover.Target>
        <ActionIcon
          variant="transparent"
          color="gray"
          onClick={() => editTagsPopoverDisclosure[1].toggle()}
        >
          {children}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <EditTagList
          label={label}
          resourceProp={resourceProp}
          initTags={resourceTags}
          entry={entry}
          onUpdateComplete={() => editTagsPopoverDisclosure[1].close()}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
