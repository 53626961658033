import { PrimitiveAtom, atom } from "jotai";
import { saveToFirebaseEffect } from "./save";
import { Epoch, PersistableModel } from "./types";
import { undoRedoEffect } from "./undoRedo";

// make 3 epochs for the last 3 years
const epochs = Array.from({ length: 3 }, (_, i) => ({
  [`epoch${3 - i}`]: {
    id: `epoch${3 - i}`,
    year: new Date().getFullYear() - i,
    type: "full",
    createdAt: Date.now() + i,
    updatedAt: Date.now() + i,
  } satisfies Epoch,
})).reduce((acc, val) => ({ ...acc, ...val }), {});

export const emptyModel: PersistableModel = {
  version: "1.1",
  cells: {},
  itemizations: {},
  general: {},
  epochs,
  paymentsMeta: {
    sba7a: {},
    sellersNote: {},
  },
};

export const coreModelAtom = atom<PersistableModel>({ ...emptyModel });

export const modelAtom = atom<PersistableModel, [PersistableModel], void>(
  (get) => {
    get(undoRedoEffect);
    get(saveToFirebaseEffect);
    //get(confirmOnCloseEffect);
    return get(coreModelAtom);
  },
  (_get, set, m) => {
    set(coreModelAtom, m);
  },
) as PrimitiveAtom<PersistableModel>;
