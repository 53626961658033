import { evaluteWithErrors as solve, toNumberOrNull } from "@/utils/math";
import { getMoleculeValueAtIndex } from "@/utils/molecule";
import { Atom, Getter, PrimitiveAtom, atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { DollarAmount } from "../types";
import {
  AmortizationPayment,
  sbaLoanAmortizationPaymentsAtom,
  sellersNoteAmortizationPaymentsAtom,
} from "./amortization";
import { equityReturnsAllInvestorsAtom } from "./equity";
import {
  buyerAnnualCapExAtom,
  buyerCompTotalAtom,
  buyerDeprAndAmortScheduleAtom,
  companySaleExitMultipleAtom,
  companySaleIncludedAtom,
  companySaleYearAtom,
  companysTaxRateAtom,
  lenderLineOfCreditDayOneDrawdownAtom,
  sbaLoanInterestRateAtom,
  sbaLoanStartDateAtom,
  targetPurchasePriceAtom,
  totalDealExpensesAtom,
} from "./general";
import { getSummaryValueAtIndex } from "./itemization";
import {
  createAggregatedIterationAtom,
  createComputedIterationMolecule,
  createGrowthRateCellAtom,
  createGrowthRateIterationMolecule,
  createStorableDollarIterationMolecule,
  iterationCountAtom,
} from "./iterations";
import { modelAtom } from "./model";
import { CellTypes } from "./types";

export const isProjectionDirtyAtom = focusAtom(modelAtom, (optic) =>
  optic.prop("general").prop("isProjectionInitialized").valueOr(false),
);

export const yearsFromSbaLoanStartDateAtom = atom((get) => {
  const startDate = get(sbaLoanStartDateAtom);
  const iterationCount = get(iterationCountAtom) || 0;
  return Array.from({ length: iterationCount }, (_, i) => {
    return i + startDate.toDate().getFullYear();
  });
});

export const grossRevenueItemization = createAggregatedIterationAtom(
  "projections|grossRevenue",
);

export const grossRevenueYoYGrowth = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const currentYear = Number(
      getSummaryValueAtIndex(grossRevenueItemization, i, get),
    );

    const previousYear = Number(
      getSummaryValueAtIndex(
        grossRevenueItemization,
        Math.max(0, i === 2 ? 0 : i - 1),
        get,
      ),
    );

    return {
      type: "percent",
      isLocked: true,
      value: i < 2 ? null : currentYear / previousYear - 1,
    };
  });
});

export const costOfGoodsSoldItemization = createAggregatedIterationAtom(
  "projections|costOfGoodsSold",
  { isNegative: true },
);

export const grossProfitMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      value:
        Number(getSummaryValueAtIndex(grossRevenueItemization, i, get)) -
        Number(getSummaryValueAtIndex(costOfGoodsSoldItemization, i, get)),
    };
  });
});

export const grossMarginsMolecule = createComputedIterationMolecule((i) =>
  atom((get) => {
    return {
      type: "percent",
      isLocked: true,
      value:
        Number(get(get(grossProfitMolecule)[i]).value) /
        Number(getSummaryValueAtIndex(grossRevenueItemization, i, get)),
    };
  }),
);

export const operatingExpensesItemization = createAggregatedIterationAtom(
  "projections|operatingExpenses",
  { isNegative: true },
);

export const buyersCompMolecule = createGrowthRateIterationMolecule(
  "projections|buyersComp",
  atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      isNegative: true,
      value: get(buyerCompTotalAtom),
    };
  }) as PrimitiveAtom<CellTypes>,
  createGrowthRateCellAtom("buyersCompGrowthCell", 0),
  { isNegative: true },
);

export const proceedsFromCompanySaleMolecule = createComputedIterationMolecule(
  (i, iter) => {
    return atom((get) => {
      const isCompanySaleIncluded = get(companySaleIncludedAtom);
      const saleYear = get(companySaleYearAtom) || 0;

      if (iter !== saleYear || !isCompanySaleIncluded)
        return { type: "dollar", isLocked: true, value: null };

      const ebitdaForYear =
        toNumberOrNull(get(get(ebitdaMolecule)[i])?.value) || 0;

      const exitMultiple =
        toNumberOrNull(get(companySaleExitMultipleAtom)) || 0;

      return {
        type: "dollar",
        isLocked: true,
        value: !get(companySaleIncludedAtom) ? 0 : ebitdaForYear * exitMultiple,
      };
    });
  },
);

export const proceedsFromCompanySaleDebtClearanceMolecule =
  createComputedIterationMolecule((_, iter) => {
    return atom((get) => {
      const saleYear = get(companySaleYearAtom) || 0;

      if (iter !== saleYear)
        return { type: "dollar", isLocked: true, value: null };

      const totalDebtService =
        toNumberOrNull(
          get(get(totalDebtServiceMolecule)[saleYear - 1]).value,
        ) || 0;

      return {
        type: "dollar",
        isLocked: true,
        value: totalDebtService,
      };
    });
  });

export const ebitdaMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const grossProfit =
      toNumberOrNull(get(get(grossProfitMolecule)[i]).value) || 0;
    const buyersComp =
      toNumberOrNull(get(get(buyersCompMolecule)[i]).value) || 0;
    const operatingExpenses =
      toNumberOrNull(
        getSummaryValueAtIndex(operatingExpensesItemization, i, get),
      ) || 0;

    return {
      type: "dollar",
      isLocked: true,
      value: solve<DollarAmount>(
        "grossProfit - buyersComp - operatingExpenses",
        {
          grossProfit,
          buyersComp,
          operatingExpenses,
        },
      ),
    };
  });
});

export const ebitdaMarginsMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "percent",
      isLocked: true,
      value:
        Number(get(get(ebitdaMolecule)[i]).value) /
        Number(getSummaryValueAtIndex(grossRevenueItemization, i, get)),
    };
  });
});

export const operatingIncomeMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      value:
        Number(get(get(ebitdaMolecule)[i]).value) -
        Number(get(get(depreciationAndAmortizationMolecule)[i]).value) -
        Number(get(get(amortOfDealExpensesMolecule)[i]).value),
    };
  });
});

const getYearlyExpense = (
  get: Getter,
  year: number,
  paymentsAtom: Atom<AmortizationPayment[]>,
  key: keyof AmortizationPayment,
) => {
  const yearPayments = get(paymentsAtom).filter((payment) => {
    return payment.yearPeriod === Math.max(1, year); // year 0 is not a valid year
  });

  return yearPayments.reduce((acc, payment) => {
    return acc + Number(payment[key]);
  }, 0);
};

const getAmortizationYearlyExpensesMolecules = (
  paymentsAtom: Atom<AmortizationPayment[]>,
) => {
  const interestMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isNegative: true,
        isLocked: true,
        value: getYearlyExpense(get, iter, paymentsAtom, "interest"),
      };
    });
  });

  const interestPaymentMolecule = createComputedIterationMolecule(
    (_i, iter) => {
      return atom((get) => {
        return {
          type: "dollar",
          isNegative: true,
          isLocked: true,
          value: getYearlyExpense(get, iter, paymentsAtom, "interestPayment"),
        };
      });
    },
  );

  const principalMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isNegative: true,
        isLocked: true,
        value: getYearlyExpense(get, iter, paymentsAtom, "principal"),
      };
    });
  });

  const extraPaymentsMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isNegative: true,
        isLocked: true,
        value: getYearlyExpense(get, iter, paymentsAtom, "fixedExtraPayment"),
      };
    });
  });

  const startingBalanceMolecule = createComputedIterationMolecule(
    (_i, iter) => {
      return atom((get) => {
        return {
          type: "dollar",
          isLocked: true,
          value:
            get(paymentsAtom)
              .filter((payment) => {
                return payment.yearPeriod === Math.max(1, iter); // year 0 is not a valid year
              })
              .shift()?.startBalance || 0,
        };
      });
    },
  );

  const endingBalanceMolecule = createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value:
          get(paymentsAtom)
            .filter((payment) => {
              return payment.yearPeriod === Math.max(1, iter); // year 0 is not a valid year
            })
            .pop()?.endingBalance || 0,
      };
    });
  });

  return {
    interestMolecule,
    interestPaymentMolecule,
    principalMolecule,
    extraPaymentsMolecule,
    startingBalanceMolecule,
    endingBalanceMolecule,
  };
};

export const {
  interestPaymentMolecule: sba7aLoanExpenseInterestPaymentMolecule,
  interestMolecule: sba7aLoanExpenseInterestMolecule,
  principalMolecule: sba7aLoanExpensePrincipalMolecule,
  startingBalanceMolecule: sba7aLoanExpenseStartingBalanceMolecule,
  endingBalanceMolecule: sba7aLoanExpenseEndingBalanceMolecule,
  extraPaymentsMolecule: sba7aLoanExpenseExtraPaymentsMolecule,
} = getAmortizationYearlyExpensesMolecules(sbaLoanAmortizationPaymentsAtom);

export const {
  interestPaymentMolecule: sellersNoteExpenseInterestPaymentMolecule,
  interestMolecule: sellersNoteExpenseInterestMolecule,
  principalMolecule: sellersNoteExpensePrincipalMolecule,
  startingBalanceMolecule: sellersNoteExpenseStartingBalanceMolecule,
  endingBalanceMolecule: sellersNoteExpenseEndingBalanceMolecule,
  extraPaymentsMolecule: sellersNoteExpenseExtraPaymentsMolecule,
} = getAmortizationYearlyExpensesMolecules(sellersNoteAmortizationPaymentsAtom);

export const depreciationAndAmortizationMolecule =
  createComputedIterationMolecule((_i, iter) => {
    return atom((get) => {
      let value =
        Number(get(targetPurchasePriceAtom)) /
        Number(get(buyerDeprAndAmortScheduleAtom));

      if (iter > Number(get(buyerDeprAndAmortScheduleAtom))) value = 0;

      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value,
      };
    });
  });

export const amortOfDealExpensesAtom = atom<DollarAmount>((get) =>
  solve<DollarAmount>("dealExpenses / buyerDeprAndAmortSchedule", {
    dealExpenses: get(totalDealExpensesAtom),
    buyerDeprAndAmortSchedule: get(buyerDeprAndAmortScheduleAtom),
  }),
);

export const amortOfDealExpensesMolecule = createComputedIterationMolecule(
  (_i, iter) => {
    return atom((get) => {
      let value = get(amortOfDealExpensesAtom);
      if (iter > Number(get(buyerDeprAndAmortScheduleAtom))) value = 0;

      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value,
      };
    });
  },
);

export const annualMaintenanceCapExMolecule = createComputedIterationMolecule(
  () => {
    return atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value: get(buyerAnnualCapExAtom),
      };
    });
  },
);

export const taxableIncomeMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const operatingIncome = Number(get(get(operatingIncomeMolecule)[i]).value);
    const interestExpensesba7aLoan = Number(
      get(get(sba7aLoanExpenseInterestMolecule)[i]).value,
    );
    const interestExpenseSellersNote = Number(
      get(get(sellersNoteExpenseInterestMolecule)[i]).value,
    );
    const annualMaintenanceCapEx =
      Number(get(get(annualMaintenanceCapExMolecule)[i]).value) || 0;

    const value = Math.max(
      0,
      operatingIncome -
        (interestExpensesba7aLoan +
          interestExpenseSellersNote +
          annualMaintenanceCapEx),
    );

    return {
      type: "dollar",
      isLocked: true,
      //isNegative: true,
      value: value,
    };
  });
});

export const companysTaxRateMolecule = createComputedIterationMolecule(() => {
  return atom((get) => {
    return {
      type: "percent",
      isLocked: true,
      value: get(companysTaxRateAtom),
    };
  });
});

export const incomeTaxesMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const taxableIncome = Number(get(get(taxableIncomeMolecule)[i]).value);
    const companysTaxRate = Number(get(get(companysTaxRateMolecule)[i]).value);
    return {
      type: "dollar",
      isLocked: true,
      isNegative: true,
      value: taxableIncome * companysTaxRate,
    };
  });
});

export const netIncomeMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const operatingIncome = Number(get(get(operatingIncomeMolecule)[i]).value);
    const incomeTaxes = Number(get(get(incomeTaxesMolecule)[i]).value);
    const interestExpensesba7aLoan = Number(
      get(get(sba7aLoanExpenseInterestMolecule)[i]).value,
    );
    const interestExpenseSellersNote = Number(
      get(get(sellersNoteExpenseInterestMolecule)[i]).value,
    );

    return {
      type: "dollar",
      isLocked: true,
      value:
        operatingIncome -
        incomeTaxes -
        interestExpensesba7aLoan -
        interestExpenseSellersNote,
    };
  });
});

export const unleveredFreeCashFlowMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const ebitda = Number(get(get(ebitdaMolecule)[i]).value);
      const incomeTaxes = Number(get(get(incomeTaxesMolecule)[i]).value);
      const annualMaintenanceCapEx =
        Number(get(get(annualMaintenanceCapExMolecule)[i]).value) || 0;

      const proceedsFromCompanySale =
        toNumberOrNull(get(get(proceedsFromCompanySaleMolecule)[i]).value) || 0;

      return {
        type: "dollar",
        isLocked: true,
        value: solve<DollarAmount>(
          "ebitda - incomeTaxes - annualMaintenanceCapEx + proceedsFromCompanySale",
          {
            ebitda,
            incomeTaxes,
            annualMaintenanceCapEx,
            proceedsFromCompanySale,
          },
        ),
      };
    });
  },
);

export const sba7aLoanPaymentMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const interest = getMoleculeValueAtIndex(
      get,
      sba7aLoanExpenseInterestMolecule,
      i,
      0,
    );

    const principal = getMoleculeValueAtIndex(
      get,
      sba7aLoanExpensePrincipalMolecule,
      i,
      0,
    );

    const extraPayment = getMoleculeValueAtIndex(
      get,
      sba7aLoanExpenseExtraPaymentsMolecule,
      i,
      0,
    );

    return {
      type: "dollar",
      isLocked: true,
      value: interest + principal + extraPayment,
      isNegative: true,
    };
  });
});

export const lineOfCreditComputedInstallmentsAtom = atom((get) => {
  const payments = get(lineOfCreditPrincipalPaymentMolecule);

  const installments: {
    startBalance: number;
    interestPayment: number;
    principalPayment: number;
    endingBalance: number;
    companySalePayment: number;
  }[] = [];

  return payments.map((_payment, i) => {
    const startBalance =
      i <= 2
        ? Number(get(lenderLineOfCreditDayOneDrawdownAtom))
        : installments[i - 1]?.endingBalance;

    const interestPayment = startBalance * Number(get(sbaLoanInterestRateAtom));
    const principalPayment = Number(
      get(get(lineOfCreditPrincipalPaymentMolecule)[i]).value,
    );

    const endingBalanceWithoutSale = Math.max(
      0,
      startBalance - principalPayment,
    );

    const isCompanySaleYear =
      get(companySaleIncludedAtom) &&
      i === Number(get(companySaleYearAtom)) + 1;

    const companySalePayment = !isCompanySaleYear
      ? 0
      : Math.max(0, endingBalanceWithoutSale);

    const endingBalance = Math.max(
      0,
      endingBalanceWithoutSale - companySalePayment,
    );

    installments.push({
      startBalance,
      interestPayment,
      principalPayment,
      companySalePayment,
      endingBalance,
    });

    return installments.slice(-1)[0];
  });
});

export const lineOfCreditStartBalanceMolecule = createComputedIterationMolecule(
  (i) =>
    atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value: get(lineOfCreditComputedInstallmentsAtom)[i].startBalance,
      };
    }),
);

export const lineOfCreditInterestPaymentMolecule =
  createComputedIterationMolecule((i) =>
    atom((get) => {
      return {
        type: "dollar",
        isNegative: true,
        isLocked: true,
        value: get(lineOfCreditComputedInstallmentsAtom)[i].interestPayment,
      };
    }),
  );

export const lineOfCreditPrincipalPaymentMolecule =
  createStorableDollarIterationMolecule(
    "lineOfCreditPrincipalPayment",
    {
      isNegative: true,
      isLocked: true,
    },
    {
      isNegative: true,
    },
  );

export const lineOfCreditPaymentMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const interest = getMoleculeValueAtIndex(
        get,
        lineOfCreditInterestPaymentMolecule,
        i,
        0,
      );

      const principal = getMoleculeValueAtIndex(
        get,
        lineOfCreditPrincipalPaymentMolecule,
        i,
        0,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: interest + principal,
        isNegative: true,
      };
    });
  },
);

export const lineOfCreditEndBalanceMolecule = createComputedIterationMolecule(
  (i) =>
    atom((get) => {
      return {
        type: "dollar",
        isLocked: true,
        value: get(lineOfCreditComputedInstallmentsAtom)[i].endingBalance,
      };
    }),
);

export const sellersNoteLoanPaymentMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const interest = getMoleculeValueAtIndex(
        get,
        sellersNoteExpenseInterestPaymentMolecule,
        i,
        0,
      );

      const principal = getMoleculeValueAtIndex(
        get,
        sellersNoteExpensePrincipalMolecule,
        i,
        0,
      );

      const extraPayment = getMoleculeValueAtIndex(
        get,
        sellersNoteExpenseExtraPaymentsMolecule,
        i,
        0,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: interest + principal + extraPayment,
        isNegative: true,
      };
    });
  },
);

export const totalDebtPaymentMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const sba7aLoan = Number(get(get(sba7aLoanPaymentMolecule)[i]).value);
    const sellersNote = Number(
      get(get(sellersNoteLoanPaymentMolecule)[i]).value,
    );

    const isCompanySaleYear =
      get(companySaleIncludedAtom) &&
      i === Number(get(companySaleYearAtom)) + 1;

    const companySalePayment = !isCompanySaleYear
      ? 0
      : Number(get(get(sba7aLoanExpenseEndingBalanceMolecule)[i]).value) +
        Number(get(get(sellersNoteExpenseEndingBalanceMolecule)[i]).value);

    return {
      type: "dollar",
      isLocked: true,
      value: sba7aLoan + sellersNote + companySalePayment,
    };
  });
});

export const totalDebtServiceMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    const payment = Number(get(get(totalDebtPaymentMolecule)[i]).value);

    const locInterest =
      Number(get(get(lineOfCreditInterestPaymentMolecule)[i]).value) || 0;
    const locPrincipal =
      Number(get(get(lineOfCreditPrincipalPaymentMolecule)[i]).value) || 0;

    return {
      type: "dollar",
      isLocked: true,
      isNegative: true,
      value: payment + locInterest + locPrincipal,
    };
  });
});

export const distributableFreeCashFlowMolecule =
  createComputedIterationMolecule((i) => {
    return atom((get) => {
      const unleveredFreeCashFlow = Number(
        get(get(unleveredFreeCashFlowMolecule)[i]).value,
      );
      const totalDebtService = Number(
        get(get(totalDebtServiceMolecule)[i]).value,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: unleveredFreeCashFlow - totalDebtService,
      };
    });
  });

export const dscrMolecule = createComputedIterationMolecule((i) => {
  return atom((get) => {
    return {
      type: "dollar",
      isLocked: true,
      value: solve<DollarAmount>(
        "abs(unleveredFreeCashFlow / totalDebtPayment)",
        {
          unleveredFreeCashFlow: get(get(unleveredFreeCashFlowMolecule)[i])
            .value,
          totalDebtPayment: get(get(totalDebtServiceMolecule)[i]).value,
        },
      ),
    };
  });
});

export const totalEquityPreferredDividendsMolecule =
  createComputedIterationMolecule((_, iter) => {
    return atom((get) => {
      const equityReturnsAllInvestors = get(equityReturnsAllInvestorsAtom);

      if (
        get(companySaleIncludedAtom) &&
        iter > Number(get(companySaleYearAtom))
      )
        return {
          type: "dollar",
          isLocked: true,
          isNegative: true,
          value: null,
        };

      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value: equityReturnsAllInvestors[iter - 1]?.preferredDividends || 0,
      };
    });
  });

export const totalEquityPrincipalRepaymentsMolecule =
  createComputedIterationMolecule((_, iter) => {
    return atom((get) => {
      const equityReturnsAllInvestors = get(equityReturnsAllInvestorsAtom);

      if (
        get(companySaleIncludedAtom) &&
        iter > Number(get(companySaleYearAtom))
      )
        return {
          type: "dollar",
          isLocked: true,
          isNegative: true,
          value: null,
        };

      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value: equityReturnsAllInvestors[iter - 1]?.principalRepayments || 0,
      };
    });
  });

export const totalEquityProfitDistributionsMolecule =
  createComputedIterationMolecule((_, iter) => {
    return atom((get) => {
      const equityReturnsAllInvestors = get(equityReturnsAllInvestorsAtom);

      if (
        get(companySaleIncludedAtom) &&
        iter > Number(get(companySaleYearAtom))
      )
        return {
          type: "dollar",
          isLocked: true,
          isNegative: true,
          value: null,
        };

      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value: equityReturnsAllInvestors[iter - 1]?.profitDistributions || 0,
      };
    });
  });

export const totalEquityInvestorsProceedsFromCompanySaleMolecule =
  createComputedIterationMolecule((_, iter) => {
    return atom((get) => {
      const equityReturnsAllInvestors = get(equityReturnsAllInvestorsAtom);

      if (
        get(companySaleIncludedAtom) &&
        iter > Number(get(companySaleYearAtom))
      )
        return {
          type: "dollar",
          isLocked: true,
          isNegative: true,
          value: null,
        };

      return {
        type: "dollar",
        isLocked: true,
        isNegative: true,
        value:
          equityReturnsAllInvestors[iter - 1]?.proceedsFromCompanySale || 0,
      };
    });
  });

export const totalEquityInvestorsDistributionsMolecule =
  createComputedIterationMolecule((i) => {
    return atom((get) => {
      const preferredDividends =
        toNumberOrNull(
          get(get(totalEquityPreferredDividendsMolecule)[i]).value,
        ) || 0;

      const principalRepayments =
        toNumberOrNull(
          get(get(totalEquityPrincipalRepaymentsMolecule)[i]).value,
        ) || 0;

      const profitDistributions =
        toNumberOrNull(
          get(get(totalEquityProfitDistributionsMolecule)[i]).value,
        ) || 0;

      const proceedsFromCompanySale =
        toNumberOrNull(
          get(get(totalEquityInvestorsProceedsFromCompanySaleMolecule)[i])
            .value,
        ) || 0;

      return {
        type: "dollar",
        isLocked: true,
        value: solve<DollarAmount>(
          "preferredDividends + principalRepayments + profitDistributions + proceedsFromCompanySale",
          {
            preferredDividends,
            principalRepayments,
            profitDistributions,
            proceedsFromCompanySale,
          },
        ),
      };
    });
  });

export const useableFreeCashFlowMolecule = createComputedIterationMolecule(
  (i) => {
    return atom((get) => {
      const distributableFreeCashFlow = Number(
        get(get(distributableFreeCashFlowMolecule)[i]).value,
      );

      const totalEquityInvestorDistributions = Number(
        get(get(totalEquityInvestorsDistributionsMolecule)[i]).value,
      );

      return {
        type: "dollar",
        isLocked: true,
        value: solve<DollarAmount>(
          "distributableFreeCashFlow - totalEquityInvestorDistributions",
          {
            distributableFreeCashFlow,
            totalEquityInvestorDistributions,
          },
        ),
      };
    });
  },
);

export const projectedCompanySalePriceMolecule =
  createComputedIterationMolecule((i) => {
    return atom((get) => {
      const ebitda = Number(get(get(ebitdaMolecule)[i]).value);
      const exitMultiple = Number(get(companySaleExitMultipleAtom));
      const saleYear = toNumberOrNull(get(companySaleYearAtom)) || 0;
      const isSaleYear = i === saleYear + 1;

      return {
        type: "dollar",
        isLocked: true,
        value: isSaleYear ? ebitda * exitMultiple : 0,
      };
    });
  });

//sum molecule
export const projectedCompanySalePriceAtom = atom((get) => {
  const atoms = get(projectedCompanySalePriceMolecule);
  const values = atoms.map((atom) => toNumberOrNull(get(atom).value) || 0);

  return values.reduce((acc, value) => acc + value, 0);
});
