import { LayoutFullscreen } from "@/components/LayoutFullscreen/LayoutFullscreen";
import { auth, prettyPrintFirebaseErrorMessage } from "@/firebase";
import { useCloneModel } from "@/hooks/useModels";
import { useRedirectWhenUserLoads } from "@/hooks/useRedirectIfUserOnPageLoad";
import { Route } from "@/paths";
import resource from "@/resources/deals";
import {
  Anchor,
  Button,
  Container,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { updateProfile } from "firebase/auth";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";

export const PageSignup = () => {
  const location = useLocation();
  const [cloneModel] = useCloneModel();
  const [createDeal] = resource.useCreate();

  useRedirectWhenUserLoads(Route.DealTracker);

  const [createUserWithEmailAndPassword, , loading, error] =
    useCreateUserWithEmailAndPassword(auth, { sendEmailVerification: true });

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      accessCode: "",
      terms: false,
    },

    validate: {
      email: (val: string) =>
        /^\S+@\S+$/.test(val) ? null : "Email format is invalid",
      password: (val: string) =>
        val.length <= 6
          ? "Password should include at least 6 characters"
          : null,
      // access code last letter must be same as first letter of email
      // access code second letter must be second letter of email
      // accessCode: (val, values) => {
      //   if (values.email.length > 0 && values.email.split("@")[0].length === 1)
      //     return null;

      //   if (val[val.length - 1].toLowerCase() !== values.email[0].toLowerCase())
      //     return "Access code is invalid";
      //   if (val[1].toLowerCase() !== values.email[1].toLowerCase())
      //     return "Access code is invalid";

      //   return null;
      // },
    },
  });

  const handleSubmit = async () => {
    const result = await createUserWithEmailAndPassword(
      form.values.email,
      form.values.password,
    );

    if (!result || !result.user) return;

    await updateProfile(result.user, { displayName: form.values.name });

    if (!location.state?.cloneModelId) return;

    const c = await cloneModel(location.state.cloneModelId, {
      name: "Homepage Model",
    });
    if (!c) return;
    await createDeal({ modelIds: [c.id] });
  };

  return (
    <LayoutFullscreen>
      <Container miw={400} size={400} my={40} pb={40}>
        <Group gap={0}>
          <Text
            flex={1}
            component={Link}
            to={Route.Login}
            ta={"center"}
            p={10}
            style={{ borderBottom: "2px solid transparent" }}
          >
            Login
          </Text>
          <Text
            flex={1}
            component={Link}
            to={Route.Signup}
            ta={"center"}
            p={10}
            style={{
              borderBottom: "2px solid var(--mantine-color-blue-5)",
            }}
            fw={600}
          >
            Create Account
          </Text>
        </Group>
        <Paper radius={0} withBorder shadow="md" p={30}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              <TextInput
                label="Name"
                placeholder="Your name"
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue("name", event.currentTarget.value)
                }
                size="md"
              />
              <TextInput
                required
                label="Email"
                placeholder="hello@example.com"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                error={form.errors.email}
                size="md"
              />
              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={form.errors.password}
                size="md"
              />
              <Button type="submit" loading={!!loading} size="md">
                Get Started
              </Button>
              <Text c="gray.6" size="sm" ta="center">
                By creating an account I agree with <br /> the{" "}
                <Anchor component={Link} to={Route.TermOfService}>
                  Terms of Services
                </Anchor>{" "}
                and{" "}
                <Anchor component={Link} to={Route.PrivacyPolicy}>
                  Privacy Policy
                </Anchor>
              </Text>
              {error && (
                <Text c="red" ta="center" mt={10}>
                  {prettyPrintFirebaseErrorMessage(error.message)}
                </Text>
              )}
            </Stack>
          </form>
        </Paper>
      </Container>
    </LayoutFullscreen>
  );
};
