import {
  Box,
  Button,
  Card,
  Container,
  Group,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { BsFillEnvelopeCheckFill } from "react-icons/bs";
import { LayoutPage } from "../LayoutPage/LayoutPage";
import { SchoolBanner } from "../PagePricing/SchoolBanner";

export const PageContactSales = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      fullName: "",
      organization: "",
      userCount: "",
      message: "",
    },

    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    console.log(values);
    setLoading(true);
    fetch(
      "https://us-central1-eta-iq-web.cloudfunctions.net/onHandleContactUsForm",
      {
        method: "POST",
        body: JSON.stringify(values),
      },
    )
      .then(() => {
        setLoading(false);
        setHasSubmitted(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <LayoutPage bg={"gray.0"}>
      <Stack
        bg={"#E0F6FF"}
        justify="center"
        align="center"
        ta={"center"}
        p={40}
      >
        <Text fw="bold" size="xl" fz={32} ta={"center"}>
          Get a custom plan built for your Search team.
        </Text>
        <Text>
          Fill us in on a few details and we’ll get back to you with a tailored
          plan
        </Text>
        <Text ta={"center"} fz={20} maw={560} fw="bold">
          We typically respond within a few hours.
        </Text>
      </Stack>
      <Container size={"sm"} py={"xl"}>
        <Stack align="center" gap="lg">
          {
            // Show success message after form submission
            hasSubmitted && (
              <Card shadow="xs" padding="lg" w={"100%"} radius={0}>
                <Stack gap="lg" align="center">
                  <BsFillEnvelopeCheckFill size={200} />
                  <Text ta={"center"} size="lg" fw="bold" maw={260}>
                    Thank you for your interest! We will contact you shortly.
                  </Text>
                </Stack>
              </Card>
            )
          }

          {!hasSubmitted && (
            <Card
              shadow="xs"
              padding="lg"
              w={"100%"}
              radius={0}
              style={{
                borderTop: "10px solid #1E88E5",
              }}
            >
              <form onSubmit={form.onSubmit(handleSubmit)}>
                <Stack>
                  <SimpleGrid cols={2} spacing="xl">
                    <Box>
                      <Stack gap="lg">
                        <TextInput
                          size="lg"
                          label="Email:"
                          key={form.key("email")}
                          {...form.getInputProps("email")}
                        />
                        <TextInput
                          size="lg"
                          label="Full Name:"
                          placeholder=""
                          key={form.key("fullName")}
                          {...form.getInputProps("fullName")}
                        />
                        <TextInput
                          size="lg"
                          label="Organization:"
                          key={form.key("organization")}
                          {...form.getInputProps("organization")}
                        />
                      </Stack>
                    </Box>
                    <Box>
                      <Stack gap="lg">
                        <Select
                          size="lg"
                          withAsterisk
                          label="How many users will need access?"
                          data={[
                            "2-3 seats",
                            "4-8 seats",
                            "9-15 seats",
                            "15+ seats",
                          ]}
                          placeholder="Select number of users"
                          key={form.key("userCount")}
                          {...form.getInputProps("userCount")}
                        />
                        <Textarea
                          minRows={1}
                          autosize
                          size="lg"
                          label="Message (optional):"
                          placeholder="Your message"
                          key={form.key("message")}
                          {...form.getInputProps("message")}
                        />{" "}
                      </Stack>
                    </Box>
                  </SimpleGrid>

                  <Group justify="center" mt={30}>
                    <Button
                      type="submit"
                      size="lg"
                      loading={loading}
                      w={"100%"}
                    >
                      Submit
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Card>
          )}
        </Stack>
      </Container>
      <Box bg={"#FCFCFC"} p={40} mt={100}>
        <SchoolBanner />
      </Box>
    </LayoutPage>
  );
};
