import { useScrollToTopOnMount } from "@/hooks/useScrollToTopOnMount";
import { Container, TypographyStylesProvider } from "@mantine/core";
import { ReactNode } from "react";
import { LayoutPage } from "../LayoutPage/LayoutPage";

export const LayoutBoilerplateText = ({
  children,
}: {
  children: ReactNode;
}) => {
  useScrollToTopOnMount();

  return (
    <LayoutPage>
      <Container size="lg" my={"xl"}>
        <TypographyStylesProvider>{children}</TypographyStylesProvider>
      </Container>
    </LayoutPage>
  );
};
