import { LayoutFullscreen } from "@/components/LayoutFullscreen/LayoutFullscreen";
import { auth, prettyPrintFirebaseErrorMessage } from "@/firebase";
import { useRedirectWhenUserLoads } from "@/hooks/useRedirectIfUserOnPageLoad";
import { Route } from "@/paths";
import {
  Anchor,
  Button,
  Container,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";

export const PageLogin = () => {
  const { state } = useLocation();
  const message = state?.message as string | undefined;

  useRedirectWhenUserLoads(Route.DealTracker);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const [signInWithEmailAndPassword, , loading, error] =
    useSignInWithEmailAndPassword(auth);

  const handleSubmit = async () => {
    await signInWithEmailAndPassword(form.values.email, form.values.password);
  };

  return (
    <LayoutFullscreen>
      <Container miw={400} size={400} my={40} pb={40}>
        {message && (
          <Text c="green" mt="xl" ta="center">
            {message}
          </Text>
        )}

        <Group gap={0}>
          <Text
            flex={1}
            component={Link}
            to={Route.Login}
            ta={"center"}
            p={10}
            style={{
              borderBottom: "2px solid var(--mantine-color-blue-5)",
            }}
            fw={600}
          >
            Login
          </Text>
          <Text
            flex={1}
            component={Link}
            to={Route.Signup}
            ta={"center"}
            p={10}
            style={{
              borderBottom: "2px solid transparent",
            }}
          >
            Create Account
          </Text>
        </Group>

        <Paper withBorder shadow="md" p={30} radius={0}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack gap={0}>
              <TextInput
                size="md"
                label="Email"
                placeholder="E.g; jane@company.com"
                required
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
              />
              <PasswordInput
                size="md"
                label="Password"
                placeholder="*************"
                required
                mt="md"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
              />

              <Button
                size="md"
                fullWidth
                mt="xl"
                loading={loading}
                type="submit"
              >
                Login
              </Button>

              {error && (
                <Text c="red" mt="xl" ta="center">
                  {prettyPrintFirebaseErrorMessage(error.message)}
                </Text>
              )}
            </Stack>
          </form>
        </Paper>
        <Group pt={"lg"} justify="center">
          <Anchor size="md" component={Link} to={Route.ForgotPassword}>
            Forgot password?
          </Anchor>
        </Group>
      </Container>
    </LayoutFullscreen>
  );
};
