import { ResourceEntry } from "@/resources/_resource";
import { Note as NoteT } from "@/resources/notes";
import { getShortId } from "@/utils/id";
import {
  ActionIcon,
  Button,
  Card,
  Group,
  Menu,
  ScrollArea,
  Spoiler,
  Stack,
  Text,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { Link, RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import { JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Timestamp, arrayRemove, arrayUnion } from "firebase/firestore";
import { memo, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { css } from "vite-plugin-inline-css-modules";
import { Icons } from "../Icons/Icons";

export const NotesWriter = ({ entry }: { entry: ResourceEntry }) => {
  const [item] = entry.useItem();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notes = ((item as any)?.notes || []) as NoteT[];

  const onSubmit = async (content: JSONContent) => {
    if (!content) return;

    console.log({
      content,
    });
    await entry.update({
      // @ts-expect-error ArrayUnion is not a valid type
      notes: arrayUnion({
        id: getShortId(),
        content,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      } satisfies NoteT),
    });
  };

  if (!item) return null;

  return (
    <ScrollArea
      h={"calc(100dvh - 43px)"}
      w={"100%"}
      bg="gray.4"
      className={linkStyles.prettyLinks}
    >
      <Stack
        bg="gray.4"
        w="100%"
        style={{
          borderLeft: "1px solid #eee",
        }}
        p={"sm"}
      >
        <Stack gap={"sm"}>
          <Text fw={600} size="sm">
            Notes
          </Text>

          <Editor onSubmit={onSubmit} />
        </Stack>

        <Stack gap={"sm"}>
          <Stack gap={"sm"}>
            {notes.reverse().map((note) => (
              <Note key={note.id} note={note} entry={entry} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </ScrollArea>
  );
};

export const Editor = memo(
  ({
    onSubmit,
    initialContent = "",
    onCancel,
    callToAction = "Add Note",
  }: {
    onSubmit: (content: JSONContent) => void;
    initialContent?: string | JSONContent;
    onCancel?: () => void;
    callToAction?: string;
  }) => {
    const [content, setContent] = useState<JSONContent>(
      typeof initialContent === "string" || !initialContent
        ? convertStringToJsonContent(initialContent)
        : initialContent,
    );

    const editor = useEditor({
      extensions: [StarterKit, Underline, Highlight, Link],
      content: content,
      onUpdate({ editor }) {
        setContent(editor.getJSON());
      },
    });

    const onHandleSubmit = () => {
      recursiveConvertUndefinedPropsToNull(content);
      onSubmit(content);
      editor?.commands.clearContent();
    };

    const onHandleCancel = () => {
      editor?.commands.clearContent();
      onCancel && onCancel();
    };

    return (
      <Card p={4}>
        <Stack gap={4}>
          <RichTextEditor editor={editor} variant="subtle">
            <RichTextEditor.Toolbar>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
              </RichTextEditor.ControlsGroup>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link /> <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
              </RichTextEditor.ControlsGroup>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.ClearFormatting />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>

          <Group>
            <Button flex={1} onClick={onHandleSubmit}>
              {callToAction}
            </Button>
            {onCancel && (
              <Button color="gray" flex={1} onClick={onHandleCancel}>
                Cancel
              </Button>
            )}
          </Group>
        </Stack>
      </Card>
    );
  },
);

export const Note = ({
  note,
  entry,
}: {
  note: NoteT;
  entry: ResourceEntry;
}) => {
  const [editingText, setEditingText] = useState<null | JSONContent | string>(
    null,
  );
  const [update] = entry.useUpdate();
  const [item] = entry.useItem();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notes = ((item as any)?.notes || []) as NoteT[];

  const content = note.content || convertStringToJsonContent(note.text);

  const editor = useEditor({
    editable: false,
    content,
    extensions: [StarterKit, Underline, Highlight, Link],
  });

  const updateNote = async (content: JSONContent) => {
    if (!notes) return;

    await update({
      notes: notes.map((n) =>
        n.id === note.id ? { ...note, content: content } : n,
      ),
    } as Partial<ResourceEntry>);
    setEditingText(null);
    editor?.commands.setContent(content);
  };

  const removeNote = async (note: NoteT) => {
    await update({
      // @ts-expect-error ArrayRemove is not a valid type
      notes: arrayRemove(note),
    });
  };

  const openDeleteModal = async () => {
    await modals.openConfirmModal({
      title: "Delete this note?",

      children: (
        <Text size="md">Are you sure you want to delete this note?</Text>
      ),
      labels: {
        confirm: `Delete`,
        cancel: "Cancel",
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        removeNote(note);
      },
    });
  };

  const onEdit = () => {
    setEditingText(note.content || note?.text);
  };

  return (
    <Card
      shadow="xs"
      style={{
        border: "1px solid var(--mantine-color-gray-2)",
      }}
      onDoubleClick={onEdit}
      p={0}
    >
      <Stack gap={"sm"}>
        {editingText !== null ? (
          <Editor
            onSubmit={updateNote}
            initialContent={editingText}
            onCancel={() => setEditingText(null)}
            callToAction="Save"
          />
        ) : (
          <>
            <Spoiler
              pl={3}
              maxHeight={92}
              showLabel={
                <Text size="sm" c="gray.7" pl={"md"}>
                  Show More
                </Text>
              }
              hideLabel={
                <Text size="sm" c="gray.7" pl={"md"}>
                  Hide
                </Text>
              }
              styles={{
                control: {
                  width: "100%",
                  //fade up to white with a gradient
                  background: "linear-gradient(rgba(255, 255, 255, 0), white)",

                  position: "absolute",
                  top: "calc(100% - 12px)",
                  height: 45,
                  fontSize: 12,
                  textAlign: "left",
                  textTransform: "uppercase",
                  textDecoration: "underline",
                  padding: "20px 0px",
                },
              }}
            >
              <RichTextEditor
                editor={editor}
                variant="subtle"
                style={{
                  border: "none",
                }}
              >
                <RichTextEditor.Content />
              </RichTextEditor>
            </Spoiler>
            <Group justify="flex-end" align="center" px={"xs"} pb={"xs"}>
              <Text size="xs" c="gray.7">
                {note.createdAt.toDate().toLocaleDateString()}
              </Text>

              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <ActionIcon color="gray.7" variant="transparent" size={"xs"}>
                    <BsThreeDots size={12} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item leftSection={<Icons.Edit />} onClick={onEdit}>
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    c={"red.7"}
                    leftSection={<Icons.Delete />}
                    onClick={() => openDeleteModal()}
                  >
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </>
        )}
      </Stack>
    </Card>
  );
};

const recursiveConvertUndefinedPropsToNull = (obj: Record<string, unknown>) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      obj[key] = null;
    } else if (typeof obj[key] === "object") {
      recursiveConvertUndefinedPropsToNull(obj[key] as Record<string, unknown>);
    }
  }
};

const convertStringToJsonContent = (text?: string): JSONContent => {
  return {
    type: "doc",
    content: text
      ? [
          {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: text,
              },
            ],
          },
        ]
      : [],
  };
};

const linkStyles = css`
  .prettyLinks {
    a {
      color: var(--mantine-color-blue-6);
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
