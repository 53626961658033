import { dealDocumentAtom } from "@/atoms/deal";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Group } from "@mantine/core";
import { useAtom } from "jotai";

export const Header = () => {
  const [[deal]] = useAtom(dealDocumentAtom);

  return (
    <LayoutWorkstation.Header>
      <Group px={"xs"} h={"100%"} w={"100%"} align="center" gap={6}>
        <LayoutWorkstation.Header.BreadcrumbTitle>
          Deal
        </LayoutWorkstation.Header.BreadcrumbTitle>
        <LayoutWorkstation.Header.BreadcrumbSeperator />
        <LayoutWorkstation.Header.BreadcrumbTitle>
          {deal?.companyName || "( Untitled Company )"}
        </LayoutWorkstation.Header.BreadcrumbTitle>
      </Group>
    </LayoutWorkstation.Header>
  );
};
