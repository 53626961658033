import { useModel } from "@/hooks/useModel";
import { Provider } from "jotai";
import { Outlet } from "react-router-dom";
import { DeferredRender } from "../DeferedRender/DeferedRender";
import { LayoutTrialEndWarning } from "../LayoutTrialEndWarning/LayoutTrialEndWarning";
import { LayoutWorkstation } from "../LayoutWorkstation/LayoutWorkStation";
import { NotAvailableOnMobile } from "../NotAvailableOnMobile/NotAvailableOnMobile";
import { AdminWarning } from "./AdminWarning";
import { LoadingSpinner } from "./Loader";
import { ModelLoadError } from "./ModelLoadError";
import { Sidebar } from "./Sidebar/Sidebar";
import { SignupDazzler } from "./SignupDazzler";
import { WelcomeVideo } from "./WelcomeVideo";

export const PageModel = () => {
  const [, loading, error] = useModel();

  if (error) {
    return <ModelLoadError />;
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <Provider>
        <LayoutTrialEndWarning>
          <LayoutWorkstation>
            <LayoutWorkstation.Sidebar visibleFrom="md">
              <Sidebar />
            </LayoutWorkstation.Sidebar>

            <DeferredRender>
              <Outlet />
            </DeferredRender>
          </LayoutWorkstation>
          <WelcomeVideo />
          <DeferredRender>
            <AdminWarning />
            <SignupDazzler />
            <NotAvailableOnMobile />
          </DeferredRender>
        </LayoutTrialEndWarning>
      </Provider>
    </>
  );
};
